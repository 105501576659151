import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DP from "./images/DannyPhantom/dp.png";
import DPBanner from "./images/DannyPhantom/banner.jpeg";

// Lazy load components
const HomePageLazy = lazy(() => import("./components/HomePage"));
const SeriesDetailLazy = lazy(() => import("./components/SeriesDetail"));
const VideoPlayerLazy = lazy(() => import("./components/VideoPlayer"));

// Loading component
const Loading = () => <div className="loading">Loading...</div>;

// App data (move this to a separate file in a real application)
export const appData = {
  featured: {
    id: 1,
    title: "Danny Phantom",
    description:
      "Danny Phantom is an American animated television show created by Butch Hartman for Nickelodeon...",
    backgroundImage: DP,
    videoUrl:
      "https://drive.google.com/file/d/1INzFeQnhObe1UB-8NOStsAnLpQn1Crli/preview",
  },
  series: [
    {
      id: 1,
      title: "Popular Drama",
      thumbnail: "https://via.placeholder.com/300x169.png?text=Popular+Drama",
      videoUrl:
        "https://drive.google.com/file/d/1INzFeQnhObe1UB-8NOStsAnLpQn1Crli/preview",
    },
    {
      id: 2,
      title: "Sci-Fi Adventure",
      thumbnail:
        "https://via.placeholder.com/300x169.png?text=Sci-Fi+Adventure",
      videoUrl:
        "https://drive.google.com/file/d/1INzFeQnhObe1UB-8NOStsAnLpQn1Crli/preview",
    },
    {
      id: 3,
      title: "Comedy Gold",
      thumbnail: "https://via.placeholder.com/300x169.png?text=Comedy+Gold",
      videoUrl:
        "https://drive.google.com/file/d/1INzFeQnhObe1UB-8NOStsAnLpQn1Crli/preview",
    },
    {
      id: 4,
      title: "Mystery Thriller",
      thumbnail:
        "https://via.placeholder.com/300x169.png?text=Mystery+Thriller",
      videoUrl:
        "https://drive.google.com/file/d/1INzFeQnhObe1UB-8NOStsAnLpQn1Crli/preview",
    },
  ],
  detailedShow: {
    id: 1,
    title: "Danny Phantom",
    description:
      "Danny Phantom is an American animated television show created by Butch Hartman for Nickelodeon, produced by Billionfold Studios. It originally ran from April 3, 2004 to August 24, 2007. The show is about Danny Fenton, a teenage boy who gained ghost powers through an accident in his parents' lab, who takes on the alter ego of Danny Phantom to save his town and the world from ghost attacks, all while struggling through school and his teenage life.",
    thumbnail: DP,
    banner: DPBanner,
    seasons: [
      {
        id: 1,
        title: "Season 1",
        episodes: [
          {
            id: 101,
            title: "01 Mystery Meat",
            videoUrl:
              "https://drive.google.com/file/d/12sR_-lFTS-0D64nyoWOUXRhuFW6-y6al/preview",
          },
          {
            id: 102,
            title: "02 Parental Bonding",
            videoUrl:
              "https://drive.google.com/file/d/184olG2hUX4awxkKgbUcGZ2L4VzlcytPb/preview",
          },
          {
            id: 103,
            title: "03 One of a Kind",
            videoUrl:
              "https://drive.google.com/file/d/1CUKQlQh8wikfBcf1mgho5294OP1rJd7Z/preview",
          },
          {
            id: 104,
            title: "04 Attack of the Killer Garage Sale",
            videoUrl:
              "https://drive.google.com/file/d/1Nh3r1-3CbV8Q0VPH13NgnhpccvM0fuJz/preview",
          },
          {
            id: 105,
            title: "05 Splitting Images",
            videoUrl:
              "https://drive.google.com/file/d/1NXLQ4ktabyg0LN9CcWjD5dMF_SdOsSyo/preview",
          },
          {
            id: 106,
            title: "06 What You Want",
            videoUrl:
              "https://drive.google.com/file/d/1pCrYvDako21aVZ8rTybRN_e5At2d5PNI/preview",
          },
          {
            id: 107,
            title: "07 Bitter Reunions",
            videoUrl:
              "https://drive.google.com/file/d/1WDUqwHQbXFfFBSWJ1-Oxo0QBnITRQ7jV/preview",
          },
          {
            id: 108,
            title: "08 Prisoners of Love",
            videoUrl:
              "https://drive.google.com/file/d/1CJxLxIh4gR8r4aNxMeFRoDXwRYn7GPBp/preview",
          },
          {
            id: 109,
            title: "09 My Brother's Keeper",
            videoUrl:
              "https://drive.google.com/file/d/1oOz96qUhLCcWUk6SMqdTCw-slRTyMyAv/preview",
          },
          {
            id: 110,
            title: "10 Shades of Gray",
            videoUrl:
              "https://drive.google.com/file/d/1AI1hAhfQukyqoDjvSQVWtNnx5Cyw0hUI/preview",
          },
          {
            id: 111,
            title: "11 Fanning the Flames",
            videoUrl:
              "https://drive.google.com/file/d/1RWWBaz_zwHWksIn7AIRt3vXLIJHnM6Z4/preview",
          },
          {
            id: 112,
            title: "12 Teacher of the Year",
            videoUrl:
              "https://drive.google.com/file/d/1VJa8U77YYbeVI8pOP6eOUG2Qq8yXYj-a/preview",
          },
          {
            id: 113,
            title: "13 Fright Night",
            videoUrl:
              "https://drive.google.com/file/d/16rjBrD0HoZ2NGpiRK6aG4fWo6V8qNXfO/preview",
          },
          {
            id: 114,
            title: "14 13",
            videoUrl:
              "https://drive.google.com/file/d/1MK8r4Ttzp9Bh1MV6xB9LjTkvzm1KaBX6/preview",
          },
          {
            id: 115,
            title: "15 Public Enemies",
            videoUrl:
              "https://drive.google.com/file/d/1h82NFGE0B2-YIgtaY8Tsf4XoiCZ1GEKf/preview",
          },

          {
            id: 116,
            title: "16 Lucky in Love",
            videoUrl:
              "https://drive.google.com/file/d/1-Qf4avZVZ4LM0uusgrcdWotWzLCsBtUF/preview",
          },
          {
            id: 117,
            title: "17 Maternal Instinct",
            videoUrl:
              "https://drive.google.com/file/d/1moUZHbBlwqdf40Owx4_pD2t430YcoKoD/preview",
          },
          {
            id: 118,
            title: "18 Life Lessons",
            videoUrl:
              "https://drive.google.com/file/d/1C5EbOYh9E50f0Px6Zl2LWLVmmSA6kfHZ/preview",
          },
          {
            id: 119,
            title: "19 The Million Dollar Ghost",
            videoUrl:
              "https://drive.google.com/file/d/1DARqetgSn0eaD1QX8c1HqOa0fYMVvWrx/preview",
          },
          {
            id: 120,
            title: "20 Control Freaks",
            videoUrl:
              "https://drive.google.com/file/d/1FhksM0CcsW_DEuBz22ntKroy1KQZatyB/preview",
          },
        ],
      },
      {
        id: 2,
        title: "Season 2",
        episodes: [
          {
            id: 121,
            title: "01 Memory Blank",
            videoUrl:
              "https://drive.google.com/file/d/1Tzz8j-GUKhRTxT2zyrrRv9DpClq9XN5G/preview",
          },
          {
            id: 122,
            title: "02 Doctor's Disorders",
            videoUrl:
              "https://drive.google.com/file/d/1eWSiDmtRnIuvxdG_rkIAzkiyeszQvNGX/preview",
          },
          {
            id: 123,
            title: "03 Pirate Radio",
            videoUrl:
              "https://drive.google.com/file/d/129VUkl1JZhly2T7ZZS_ZeG6LkJTmIzLi/preview",
          },
          {
            id: 124,
            title: "04 Reign Storm Pt 1",
            videoUrl:
              "https://drive.google.com/file/d/1Fc_kFh27zWVvU2H4EOWvTN0dAEXhqWYl/preview",
          },
          {
            id: 125,
            title: "05 Reign Storm Pt 2",
            videoUrl:
              "https://drive.google.com/file/d/1FnMXBbmWeyiAz4BAQP_yRL3A66MaOA3Z/preview",
          },
          {
            id: 126,
            title: "06 Identity Crisis",
            videoUrl:
              "https://drive.google.com/file/d/1trLBPfCB2FU4oltaLsRXhRukSN8wE5En/preview",
          },
          {
            id: 127,
            title: "07 The Fenton Menace",
            videoUrl:
              "https://drive.google.com/file/d/1hZwesDb4F4Q7P32xhQfutoYSIA46YaMY/preview",
          },
          {
            id: 128,
            title: "08 + 09 The Ultimate Enemy",
            videoUrl:
              "https://drive.google.com/file/d/1NlYOTOG_041syU6ZlkaYbbCWD4mYBVvM/preview",
          },
          {
            id: 129,
            title: "10 The Fright Before Christmas",
            videoUrl:
              "https://drive.google.com/file/d/1Mw5IUx3yS35pFuw_MToRbymC9lSZ2GWk/preview",
          },
          {
            id: 130,
            title: "11 Secret Weapons",
            videoUrl:
              "https://drive.google.com/file/d/1ETdJoLhQ5Dt-ZZ7ho8sAzcQk-KOlz_C5/preview",
          },
          {
            id: 131,
            title: "12 Flirting with Disaster",
            videoUrl:
              "https://drive.google.com/file/d/1zXHvcU3ktei6Cvn7uWFfU8HuaOlcw0jR/preview",
          },
          {
            id: 132,
            title: "13 Micro Management",
            videoUrl:
              "https://drive.google.com/file/d/178lkIEmrUToa1T6R21s2o6-Id_2g3xhX/preview",
          },
          {
            id: 133,
            title: "14 Beauty Marked",
            videoUrl:
              "https://drive.google.com/file/d/1Xg_2tkS3FelEnSyBGy4EFsEmS8K3tMoG/preview",
          },
          {
            id: 134,
            title: "15 King Tuck",
            videoUrl:
              "https://drive.google.com/file/d/1XtUhYXc04NuVoGvILn7XLWQanBcmvn_d/preview",
          },
          {
            id: 135,
            title: "16 Masters of All Time",
            videoUrl:
              "https://drive.google.com/file/d/1INzFeQnhObe1UB-8NOStsAnLpQn1Crli/preview",
          },
          {
            id: 136,
            title: "17 Kindred Spirits & 18 Double Cross My Heart",
            videoUrl:
              "https://drive.google.com/file/d/1rG9Q397hCFuxAb_LLmsvlYy2pV-V5oWf/preview",
          },
          {
            id: 137,
            title: "19 Reality Trip Pt 1",
            videoUrl:
              "https://drive.google.com/file/d/1dz1c4rKpla5bWHBRhLOwIa4aznITeKmU/preview",
          },
          {
            id: 138,
            title: "20 Reality Trip Pt 2",
            videoUrl:
              "https://drive.google.com/file/d/1sCWEEv4irZTA2d1_OPSt20pEhNZC95OH/preview",
          },
        ],
      },
      {
        id: 3,
        title: "Season 3",
        episodes: [
          {
            id: 139,
            title: "01 Eye for an Eye",
            videoUrl:
              "https://drive.google.com/file/d/1vecxMorXfH3c3Qqu4dKWM-RaGjagdQ_P/preview",
          },
          {
            id: 140,
            title: "02 Infinite Realms",
            videoUrl:
              "https://drive.google.com/file/d/1HnT5sZMo23iyA1zsZyZNAdhaEuq2qun9/preview",
          },
          {
            id: 141,
            title: "03 Girls' Night Out",
            videoUrl:
              "https://drive.google.com/file/d/1AJPMUV7TTwmxl4j_DDtBzlVNUXNEzQ_c/preview",
          },
          {
            id: 142,
            title: "04 Torrent of Terror",
            videoUrl:
              "https://drive.google.com/file/d/110N05PlpP_utnp_UgB0zMBotF0Ti7r1A/preview",
          },
          {
            id: 143,
            title: "05 Forever Phantom",
            videoUrl:
              "https://drive.google.com/file/d/1GDU0C6TwZAX7Hcht-S5YM6FcsTbw1dDr/preview",
          },
          {
            id: 144,
            title: "06 Urban Jungle",
            videoUrl:
              "https://drive.google.com/file/d/11EwbhSFRjIgt8Jbw8-ZuEkZrNfOFVMG6/preview",
          },
          {
            id: 145,
            title: "07 Livin' Large",
            videoUrl:
              "https://drive.google.com/file/d/1YJF4zS13HxGj_6q17gg0Fiyuh25ZowpJ/preview",
          },
          {
            id: 146,
            title: "08 Boxed-Up Fury",
            videoUrl:
              "https://drive.google.com/file/d/1ArLRDt4g-i0_Tu7nrW-wGvk9rKfBfN1f/preview",
          },
          {
            id: 147,
            title: "09 Frightmare",
            videoUrl:
              "https://drive.google.com/file/d/1FrdreJphXBqdf0lO1CeHU43jw8kW3tf-/preview",
          },
          {
            id: 148,
            title: "10 Claw of the Wild",
            videoUrl:
              "https://drive.google.com/file/d/1l71QzSfHpIfOXd355Dh7ylKMxHZMNmW8/preview",
          },
          {
            id: 149,
            title: "11 D-Stabilized",
            videoUrl:
              "https://drive.google.com/file/d/1gw_2bSzsxCTW2yLfKVlBSM3F8PYkPPEd/preview",
          },
        ],
      },
    ],
  },
};

const App = () => {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<HomePageLazy />} />
          <Route path="/series/:id" element={<SeriesDetailLazy />} />
          <Route path="/watch/:id" element={<VideoPlayerLazy />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
